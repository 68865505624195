<template v-if="formActive">

  <div class="row mb-2">
    <div class="col-3 fw-bold" style="padding-start: 36px">{{$t(reversePortOrder ? 'manifest.ports_inv' : 'manifest.ports')}}&nbsp;
      <button type="button" class="btn p-0" @click="setPortOrder"><i class="fa fa-rotate"></i></button>
    </div>
    <div class="col-0p75 fw-bold">B/Ls</div>
    <div class="col-1 fw-bold">Type</div>
    <div class="col-1 fw-bold">B/L Status</div>
    <div class="col-1 fw-bold">Approved</div>
    <div class="col-1 fw-bold text-center">Carrier B/L</div>
    <div class="col-auto" style="width: 11%"></div>
    <div class="col-3">
      <FormItem type="select" :options="operatedByOptions" v-model="operatedByOfficeId" :selectDefault="false" @change="filterByOperator" />
    </div>
  </div>
  <div class="ps-4 ms-1">
    <p v-show="chosenPorts.length == 0">{{ $t('form.no_found', [$tc('navigation.bills_of_lading', 2)]) }}</p>
    <div :class="{ 'd-none': !listLoading }"> <i class="fas fa-spinner fa-spin"></i> {{$t('form.please_wait')}} </div>
  </div>
  <template v-if="chosenPorts.length > 0 && !listLoading">
      <div class="header-part-1">
        <template v-for="pol in (reversePortOrder ? chosenPortsReverse : chosenPorts)" :key="pol.id">
          <div class="row data-tr" :class="{'published': pol.status == 'published'}">
            <div class="col-3 fw-bold">
              <FormItem :id="'pol-'+pol.id" :label="pol.name" v-model="pol.chosen" type="checkbox" @onchange="(e) => setChosenPOLs(e, pol)" />
            </div>
            <div class="col-0p75 pt-1"> {{pol.bl_count}} </div>
            <div class="col-1 pt-1"> </div>
            <div class="col-1 pt-1"> </div>
            <div class="col-1 pt-1"> </div>
          </div>
          <template v-for="pod in pol.pods" :key="pod.id">
            <div class="row data-tr" :class="{'published': pod.status == 'published'}">
              <div class="col-1 pe-0 text-end" style="width: 27px; padding-top: 2px">
                <FormItem :id="'pol-'+pol.id+'-'+pod.id" v-model="pod.chosen" type="checkbox" @onchange="(e) => setChosenPODs(e, pod, pol)" />
              </div>
              <div class="col-1 ps-2" style="width: calc(25% - 27px)">
                <button :id="'btn-'+pol.id+'-'+pod.id" class="btn py-0 px-2 no-box-shadow" type="button" @click="pod.expanded = !pod.expanded" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+pol.id+'-'+pod.id">
                  <i class="fa fa-chevron-right" :class="{'rotate-90deg': pod.expanded}"></i>
                </button>
                <label class="col-form-label col-form-label-sm auto-check-label ps-1 fw-bold" :for="'pol-'+pol.id+'-'+pod.id">{{pod.name}}</label>
              </div>
              <div class="col-0p75 pt-1"> {{pod.bl_count}} </div>
              <div class="col-1"> </div>
              <div class="col-1"> </div>
              <div class="col-1 pt-1"> </div>
            </div>
            <div :id="'collapse-' +pol.id + '-' + pod.id" class="collapse">
              <div class="row mb-1 data-tr" v-for="bl in pod.bills_of_lading" :key="bl.id">
                <div class="col-1 ps-4 pe-0 text-end" style="width: 53px; padding-top: 2px">
                  <FormItem :id="'bl-'+bl.id+'-'+pol.id+'-'+pod.id" v-model="bl.chosen" type="checkbox" @onchange="(e) => setChosenBLs(e, pol, pod, bl)" />
                </div>
                <div class="col-3 ps-2" style="width: 27%">
                  <label class="col-form-label col-form-label-sm auto-check-label ps-1" :for="'bl-'+bl.id+'-'+pol.id+'-'+pod.id">{{bl.name}}</label>
                </div>
                <div class="col-1 pt-1"> {{bl.type}} </div>
                <div class="col-1 pt-1 text-capitalize"> {{ $t('bl_status.' + bl.bill_of_lading_status_id) }} </div>
                <div class="col-1 pt-1 text-capitalize"> <i class="fa fa-check text-success pt-1" v-show="bl.supervisor_approval_id == 4"></i> </div>
                <div class="col-1 text-center"> <i class="fa fa-check text-success pt-1" v-show="bl.is_carrier_bl"></i> </div>
              </div>
            </div>
          </template>
        </template>
          
      </div>

      <template v-if="itemLoaded && xmlPreview">

        <div class="modal d-block" tabindex="-1" style="background-color: rgba(1,1,1,0.4)" @click="closeInnerModel">
            <div class="modal-dialog modal-dialog-scrollable modal-lg" style="min-width: 1200px !important">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="w-100" v-if="generatedHTML" v-html="generatedHTML"></div>
                        <pre v-else-if="manifestType > 4 && xmlManifestType === manifestType">{{xmlPreview}}</pre>
                        <p v-else>{{ $t('manifest.click_generate', [manifestType == 8 ? formatTypesBL[formatType - 1].name : (manifestType > 4 ? formatTypesXML[formatType - 1].name : formatTypes[formatType - 1].name)]) }}</p>
                    </div>
                </div>
            </div>
        </div>

      </template>

  </template>

</template>

<script>
  import voyageService from '@/services/VoyageService';
  import voyageManifestService from '@/services/VoyageManifestService';
  import moment from 'moment';
  import store from '@/store/user';
  export default {
     watch: {
      itemData: function (val) {
        this.voyage = val    
      },
    },
    data() {
      return {
        generatedHTML: '',
        itemLoaded: false,
        itemLoading: false,
        listLoading: false,
        voyage: {
          ports: []
        },
        nmtCompany: 1,
        reversePortOrder: false,
        hasChannelSet: false,
        formatType: 1,
        manifestType: 3,
        showPreview: true,
        chosenPorts: [],
        chosenPortsReverse: [],
        chosenPols: [],
        chosenPods: [],
        chosenBLs: [],
        operatedByOptions: [],
        operatedByOfficeId: null,
        allBLs: {},
        portsIdCode: {},
        xmlPreview: '',
        xmlManifestType: 0,
        downloadType: 2,
        extraOptions: {"shipper": 1, "units": 1, "tabs": 1, "dimensions": 1, "format": 1, "relations": 1, "show_description": 0, "bltype": 1, "background": 1, "zipped": 1, "redtext": 0, "canada": 0, "consignee": 1, "show_blnumber": 0},
        manifestTypes: [{id: 1, name: 'Normal'}, {id: 2, name: 'Measurement'}, {id: 3, name: 'Agent freight'}, {id: 4, name: 'Carrier Freight'},
                        {id: 5, name: '(XML) Namibia'}, {id: 6, name: '(XML) Balloré'}, {id: 7, name: '(XML) GovCBR'}, {id: 8, name: 'Bills of Lading'}],
        formatTypes: [{id: 1, name: 'Preview'}, {id: 2, name: 'Download PDF'}, {id: 3, name: 'Download CSV'}, {id: 4, name: 'Download Excel'}],
        formatTypesBL: [{id: 1, name: 'PDF'}, {id: 2, name: 'Zip File'}],
        formatTypesXML: [{id: 1, name: 'XML'}],
        formActive: false
      };
    },

    methods: {
      active(value){
          this.formActive = value;
      },
      validate(){
        return false;
      },
      getData() {
        return this.voyage
      },
      filterByOperator(){
        const id = this.voyage.id + 1 - 1;
        this.voyage = {};
        this.reset();
        this.listLoading = true;
        voyageManifestService.show(id, this.operatedByOfficeId).then(response => this.setData(response.data));
      },
      reset(){
        this.$parent.$parent.$parent.chosenBLLength = 0;
        this.$parent.$parent.$parent.disableOriginal();
        this.hasChannelSet = false;
      },
      setData(data) {
        this.voyage = data;
        this.chosenPorts = [];
        this.chosenPortsReverse = [];
        this.chosenPols = [];
        this.chosenPods = [];
        this.chosenBLs = [];
        this.allBLs = {};
        this.operatedByOptions = data.operators;
        this.operatedByOptions.forEach(opt => opt.name = opt.relation.name);
        this.operatedByOptions.sort((a, b) => a.name > b.name ? 1 : -1);
        this.operatedByOfficeId = data.operated_by_office_id;
        this.listLoading = false;
        if(!this.hasChannelSet){
          this.generatedHTML = '';
          this.formatType = 1;
          this.manifestType = 3;
          this.xmlPreview = '';
          this.showPreview = true;
          this.reversePortOrder = false;
          const el = document.getElementById('detailsSpan');
          if(el){
            el.innerHTML = data.name;
            this.$nextTick(() => el.previousElementSibling.innerHTML = "");
          }
        }
        for(let pol in data.ports){
          const newpol = data.ports[pol];
          this.portsIdCode[newpol.id] = newpol.code;
          let commstatus = this.voyage.voyage_port_statuses.find(cs => cs.port_of_loading_id == newpol.id);
          if(!commstatus) commstatus = this.voyage.voyage_port_statuses.find(cs => cs.port_of_loading_id === null);
          let blcount = 0;
          let approvedcount = 0;
          for(let pod in newpol.pods){
            const newpod = newpol.pods[pod];
            blcount += newpod.bills_of_lading.length;
            approvedcount += newpod.bills_of_lading.filter(bl => bl.job_booking_voyage_manifest && bl.job_booking_voyage_manifest.is_approved_by_supervisor).length;
          }
          let newPOLIndex = this.chosenPorts.push({
            id: newpol.id,
            name: newpol.name,
            chosen: false,
            code: pol,
            pods: [],
            bl_count: blcount,
            status: commstatus ? commstatus.status : 'open',
            publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
            approved_count: approvedcount
          });
          this.voyage.ports[pol].BLsByNumber = [];
          for(let pod in newpol.pods){
            const newpod = newpol.pods[pod];
            this.portsIdCode[newpod.id] = newpod.code;
            let commstatus = this.voyage.voyage_port_statuses.find(cs => (cs.port_of_loading_id == newpol.id && cs.port_of_discharge_id == newpod.id));
            if(!commstatus) commstatus = this.voyage.voyage_port_statuses.find(cs => (cs.port_of_loading_id === null && cs.port_of_discharge_id == newpod.id));
            if(!commstatus){
              const newstatusindex = this.voyage.voyage_port_statuses.push({
                commodity_id: null,
                port_of_discharge_id: newpod.id,
                port_of_loading_id: newpol.id,
                published_by_user_id: null,
                published_date: null,
                status: 'open',
                voyage_id: this.voyage.id
              });
              commstatus = this.voyage.voyage_port_statuses[newstatusindex - 1];
            }
            const blApprovedCount = newpod.bills_of_lading.filter(bl => bl.job_booking_voyage_manifest && bl.job_booking_voyage_manifest.is_approved_by_supervisor).length;
            let newPODIndex = this.chosenPorts[newPOLIndex - 1].pods.push({
              id: newpod.id,
              name: newpod.name,
              chosen: false,
              expanded: false,
              code: newpod.code,
              bl_count: newpod.bills_of_lading.length,
              status: commstatus ? commstatus.status : 'open',
              publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
              approved_count: blApprovedCount,
              bills_of_lading: []
            });
            let foundPortIndex = this.chosenPortsReverse.findIndex(port => port.id === newpod.id);
            let foundPortSubIndex = 0;
            if(foundPortIndex >= 0){
              foundPortSubIndex = this.chosenPortsReverse[foundPortIndex].pods.push({ 
                id: newpol.id, 
                name: newpol.name, 
                chosen: false, 
                bills_of_lading: [],
                bl_count: newpod.bills_of_lading.length,
                status: commstatus ? commstatus.status : 'open',
                publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
                approved_count: blApprovedCount,
              }) - 1;
              this.chosenPortsReverse[foundPortIndex].approved_count += blApprovedCount;
              this.chosenPortsReverse[foundPortIndex].bl_count += blcount;
            }
            else{
              foundPortIndex = this.chosenPortsReverse.push({
                id: newpod.id,
                name: newpod.name,
                chosen: false,
                bl_count: blcount,
                status: commstatus ? commstatus.status : 'open',
                publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
                approved_count: blApprovedCount,
                pods: [{ 
                  id: newpol.id, 
                  name: newpol.name, 
                  chosen: false, 
                  bills_of_lading: [],
                  bl_count: newpod.bills_of_lading.length,
                  status: commstatus ? commstatus.status : 'open',
                  publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
                  approved_count: blApprovedCount,
                }]
              }) - 1;
            }
            newpod.bills_of_lading.forEach(bl => {
              this.allBLs[bl.id] = bl;
              this.chosenPorts[newPOLIndex - 1].pods[newPODIndex - 1].bills_of_lading.push({
                chosen: 0,
                id: bl.id,
                name: bl.number,
                is_carrier_bl: !bl.job_booking_voyage_manifest.is_nmt_bl,
                type: bl.bl_type,
                bill_of_lading_status_id: bl.bill_of_lading_status_id,
                supervisor_approval_id: bl.supervisor_approval_id
              });
              this.chosenPortsReverse[foundPortIndex].pods[foundPortSubIndex].bills_of_lading.push({
                chosen: 0,
                id: bl.id,
                name: bl.number,
                is_carrier_bl: !bl.job_booking_voyage_manifest.is_nmt_bl,
                type: bl.bl_type,
                bill_of_lading_status_id: bl.bill_of_lading_status_id,
                supervisor_approval_id: bl.supervisor_approval_id
              });
              if(!bl.job_booking_voyage_manifest){
                bl.job_booking_voyage_manifest = {
                  bill_of_lading_id: bl.id,
                  is_approved_by_supervisor: false,
                  is_consolidated: false,
                  is_nmt_bl: false,
                  job_booking_voyage_id: bl.job_booking_id,
                  bill_of_lading_status_id: bl.bill_of_lading_status_id,
                  supervisor_approval_id: bl.supervisor_approval_id
                }
              }
              this.voyage.ports[pol].BLsByNumber.push({
                id: bl.id,
                form_id: newpod.id + '-' + bl.id,
                job_booking_voyage_manifest: bl.job_booking_voyage_manifest,
                number: bl.number,
                booking_index: Number(bl.booking_number.slice(-2)) - 1,
                pod_id: newpod.id,
                job_id: bl.job_id,
                job_booking_id: bl.job_booking_id,
                unit_count: bl.unit_count,
                pol: bl.pol,
                pod: bl.pod,
                code: newpod.code,
                loaded_count: bl.loaded_count,
                office: bl.office ? bl.office : '-',
                is_accepted: bl.bill_of_lading_status_id == 3,
                status: commstatus.status,
                bill_of_lading_status_id: bl.bill_of_lading_status_id,
                supervisor_approval_id: bl.supervisor_approval_id
              });
            });
          }
          this.voyage.ports[pol].BLsByNumber.sort((a,b) => { return a.number > b.number ? 1 : -1 });
          //this.chosenPorts[newPOLIndex - 1].pods.sort((a,b) => { return a.name > b.name ? 1 : -1 });
        }

        if(!this.hasChannelSet){
          window.activeChannel = `return-manifest-${this.voyage.id}`;
          window.Echo.channel(window.activeChannel)
            .listen('.listen-manifest', (e) => {
              console.log('broadcast', e);
              let foundPOL = this.chosenPorts.find(port => port.id == e.portOfLoadingId);
              if(foundPOL){
                foundPOL.approved_count += (e.checklistValue ? 1 : -1);
                let foundPOD = foundPOL.pods.find(port => port.id == e.portOfDischargeId);
                if(foundPOD){
                  foundPOD.approved_count += (e.checklistValue ? 1 : -1);
                }
                let foundBL = this.voyage.ports[foundPOL.code].BLsByNumber.find(bl => bl.job_booking_voyage_manifest.id == e.checklistId);
                if(foundBL){
                  foundBL.job_booking_voyage_manifest.is_approved_by_supervisor = e.checklistValue;
                }
              }
          });
          window.Echo.channel(`return-export-print-${store.getters.userId}`)
            .listen('.listen-export-print', (e) => {
              this.$parent.$parent.$parent.progress = e.progress;
          });
          this.hasChannelSet = true;
        }
      },

      setPublishedDateString(date){
        const dateItem = date ? new Date(date) : new Date();
        return new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short', hour12: false }).format(dateItem).replace(" at ", " ");
      },

      generate(){
        this.$parent.$parent.$parent.itemLoading = true;
        if(this.downloadType == 2){
          this.downloadAllBLs();
          return;
        }
        this.itemLoaded = false;
        this.xmlManifestType = this.manifestType + 1 - 1;
        const filetypeindex = this.downloadType == 3 ? (this.extraOptions.tabs == 3 ? 4 : 3) : this.formatType;
        const download_types = ['', 'MANIFEST', 'BL', 'REQUEST'];
        const format = ['', 'PDF', 'CSV', 'EXCEL', 'EXCEL'];
        const manifest_type = ['CARRIER_BL_REQUEST', 'NORMAL', 'MEASUREMENT', 'AGENT_FREIGHT', 'CARRIER_FREIGHT', 'XML_NAMIBIA', 'XML_BOLORE', 'XML_GOVCBR', 'ASM_FILING', 'VINS_LIST', 'PRELOAD_CARGO'];
        const filename_type = ['blrequest', 'normal', 'measurement', 'agentfreight', 'carrierfreight', 'namibia', 'bolore', 'govcbr', 'amsfiling', 'vinslist', 'preloadcargo'];
        const format_name = (!(this.downloadType == 3 || this.manifestType <= 4 || this.manifestType > 7) && this.manifestType > 4) ? 'XML' : format[filetypeindex];
        voyageService.showManifestPost(this.voyage.id, this.chosenPols, this.chosenPods, this.chosenBLs, download_types[this.downloadType], format_name, manifest_type[this.manifestType], this.extraOptions).then(response => {
          this.generatedHTML = '';
          this.xmlPreview = '';
          let polstring = '';
          let podstring = '';
          let polpodstring = '';
          if(this.chosenPols.length < 5){
            this.chosenPols.forEach(id => {
              polstring += this.portsIdCode[id].toLowerCase();
            });
          }
          else{
            polstring = 'multiplepol';
          }
          this.chosenPods.forEach(id => {
            const code = this.portsIdCode[id].toLowerCase();
            if(!podstring.includes(code)) podstring += code;
          });
          if(podstring.length > 13){
            podstring = 'multiplepod';
          }
          polpodstring = this.chosenPols.length >= 5 && podstring.length > 13 ? '' : (polstring + '_' + podstring);
          const fielnameindex = this.downloadType == 3 ? ('_' + filename_type[0]) : ((this.manifestType == 3 || this.manifestType == 4) ? '_FRT' : '');
          const fileTypes = ['', '.pdf', '.csv', '.xlsx', '.zip'];
          const isForXML = this.manifestType > 4 && this.manifestType <= 7 && this.downloadType != 3;
          let filename = (this.voyage.number + '_' + polpodstring + fielnameindex).toUpperCase() + (isForXML ? '.xml' : fileTypes[filetypeindex]);
          if(download_types[this.downloadType] == 'MANIFEST' && format_name == "EXCEL"){
            filename = "EXCEL " + polpodstring.replaceAll('_', '').toUpperCase() + fileTypes[filetypeindex];
          }
          else if(download_types[this.downloadType] == 'MANIFEST' && fielnameindex == '_FRT'){
            filename = (polpodstring + fielnameindex).toUpperCase() + fileTypes[filetypeindex];
          }
          this.triggerDownload(response.data, filename);
          this.itemLoaded = this.formatType == 1;
          this.$parent.$parent.$parent.itemLoading = false;
        }).catch(error => {
            this.toastError(error)
            console.log('error', error) // create notification-toaster for user error
            this.$parent.$parent.$parent.itemLoading = false;
        });
      },

      downloadAllBLs() {
        const zipped = this.extraOptions.zipped == 2;
        return voyageService.showAllBLs(this.chosenBLs, zipped, this.extraOptions).then(response => {
            const name = this.chosenBLs.length == 1 ? (this.allBLs[this.chosenBLs[0]].number) : (this.voyage.number + "_bl_summaries");
            this.triggerDownload(response.data, name + (zipped ? '.zip' : '.pdf'));
            this.$parent.$parent.$parent.itemLoading = false;
        }).catch(error => {
            this.toastError(error);
            this.$parent.$parent.$parent.itemLoading = false;
        });
      },

      setChosenPOLs(e, port){
        const chosen = this.reversePortOrder ? this.chosenPods : this.chosenPols;
        const otherChosen = this.reversePortOrder ? this.chosenPols : this.chosenPods;
        if(e.target.checked){
          if(!chosen.includes(port.id)) chosen.push(port.id);
          port.hasOne = true;
          port.pods.forEach(pod => {
            if(!pod.chosen){
              pod.chosen = 1;
              otherChosen.push(pod.id);
            }
            pod.bills_of_lading.forEach(bl => {
              if(!bl.chosen){
                bl.chosen = 1;
                this.chosenBLs.push(bl.id);
              }
            });
          });
        }
        else{
          port.hasOne = false;
          const index = chosen.findIndex(ch => ch == port.id);
          if(index !== -1) {
            console.log('del')
            chosen.splice(index, 1);
          }
          port.pods.forEach(pod => {
            if(pod.chosen){
              const index2 = otherChosen.findIndex(ch => ch == pod.id);
              if(index2 !== -1) {
                otherChosen.splice(index2, 1);
                pod.chosen = 0;
              }
            }
            pod.bills_of_lading.forEach(bl => {
              if(bl.chosen){
                const index3 = this.chosenBLs.findIndex(ch => ch == bl.id);
                if(index3 !== -1) {
                  this.chosenBLs.splice(index3, 1);
                  bl.chosen = 0;
                }
              }
            });
          });
        }
        this.checkForDrafts();
      },

      setChosenPODs(e, port, upperPol){
        const chosen = this.reversePortOrder ? this.chosenPols : this.chosenPods;
        const otherChosen = this.reversePortOrder ? this.chosenPods : this.chosenPols;
        const polAbove = this.reversePortOrder ? port.id: upperPol.id;
        if(e.target.checked){
          chosen.push(port.id);
          if(!upperPol.hasOne){
            upperPol.chosen = upperPol.pods.find(pod => !pod.chosen) ? 0 : 1;
            upperPol.hasOne = true;
            otherChosen.push(upperPol.id);
          }
          port.bills_of_lading.forEach(bl => {
            if(!bl.chosen){
              bl.chosen = 1;
              this.chosenBLs.push(bl.id);
            }
          });
        }
        else{
          const index = chosen.findIndex(ch => ch == port.id);
          if(index !== -1) {
            chosen.splice(index, 1);
            port.bills_of_lading.forEach(bl => {
              if(bl.chosen){
                const index2 = this.chosenBLs.findIndex(ch => ch == bl.id);
                if(index2 !== -1) {
                  this.chosenBLs.splice(index2, 1);
                  bl.chosen = 0;
                }
              }
            });
          }
          let allPODsUnselected = true;
          for(let port of upperPol.pods){
            if(port.chosen){
              allPODsUnselected = false;
              break;
            }
          }
          if(allPODsUnselected){
            const index = otherChosen.findIndex(ch => ch == polAbove);
            if(index !== -1) {
              otherChosen.splice(index, 1);
            }
          }
          upperPol.chosen = 0;
          upperPol.hasOne = upperPol.pods.find(pod => pod.chosen) ? 1 : 0;
        }
        this.checkForDrafts();
      },

      setChosenBLs(e, upperPol, upperPod, bl){
        const chosen = this.chosenBLs;
        const podAbove = this.reversePortOrder ? upperPol.id: upperPod.id;
        const polAbove = this.reversePortOrder ? upperPod.id: upperPol.id;
        if(e.target.checked){
          chosen.push(bl.id);
          if(!upperPod.chosen){
            upperPod.chosen = upperPod.bills_of_lading.find(bl => !bl.chosen) ? 0 : 1;
            upperPod.hasOne = true;
            this.chosenPods.push(upperPod.id);
          }
          if(!upperPol.hasOne){
            upperPol.chosen = upperPol.pods.find(pod => !pod.chosen) ? 0 : 1;
            upperPol.hasOne = true;
            this.chosenPols.push(upperPol.id);
          }
        }
        else{
          const index = chosen.findIndex(ch => ch == bl.id);
          if(index === -1) return;
          chosen.splice(index, 1);
          let allBLsUnselected = true;
          for(let otherBL of upperPod.bills_of_lading){
            if(otherBL.chosen){
              allBLsUnselected = false;
              break;
            }
          }
          if(allBLsUnselected){
            const index = this.chosenPods.findIndex(ch => ch == podAbove);
            if(index === -1) return;
            this.chosenPods.splice(index, 1);
            let allPODsUnselected = true;
            for(let port of upperPol.pods){
              if(port.chosen){
                allPODsUnselected = false;
                break;
              }
            }
            if(allPODsUnselected){
              const index2 = this.chosenPols.findIndex(ch => ch == polAbove);
              if(index2 !== -1)
                this.chosenPols.splice(index2, 1);
            }
          }
          upperPod.chosen = 0;
          upperPol.chosen = 0;
          upperPol.hasOne = upperPol.pods.find(pod => pod.chosen) ? 1 : 0;
        }
        this.checkForDrafts();
      },

      checkForDrafts(){
        const parent = this.$parent.$parent.$parent;
        parent.chosenBLLength = this.chosenBLs.length;
        let hasDrafts = parent.chosenBLLength == 0;
        for(let id of this.chosenBLs){
          if(this.allBLs[id].bill_of_lading_status_id < 3 || this.allBLs[id].supervisor_approval_id < 4){
            hasDrafts = true;
            break;
          }
        }
        if(hasDrafts)
          parent.disableOriginal();
        else
          parent.enableOriginal();
      },

      setPortOrder(){
        this.reversePortOrder = !this.reversePortOrder;
        let buttonIds = [];
        if(this.reversePortOrder){
          for(let pol of this.chosenPortsReverse){
            for(let pod of pol.pods){
              for(let bl of pod.bills_of_lading){
                bl.chosen = this.chosenBLs.includes(bl.id) ? 1 : 0;
              }
              pod.chosen = pod.bills_of_lading.find(bl => !bl.chosen) ? 0 : 1;
            }
            pol.chosen = pol.pods.find(pod => !pod.chosen) ? 0 : 1;
          }
          for(let pol of this.chosenPorts){
            for(let pod of pol.pods){
              if(pod.expanded){
                buttonIds.push('btn-'+pod.id+'-'+pol.id);
                pod.expanded = false;
              }
            }
          }
        }
        else{
          for(let pol of this.chosenPorts){
            for(let pod of pol.pods){
              for(let bl of pod.bills_of_lading){
                bl.chosen = this.chosenBLs.includes(bl.id) ? 1 : 0;
              }
              pod.chosen = pod.bills_of_lading.find(bl => !bl.chosen) ? 0 : 1;
            }
            pol.chosen = pol.pods.find(pod => !pod.chosen) ? 0 : 1;
          }
          for(let pol of this.chosenPortsReverse){
            for(let pod of pol.pods){
              if(pod.expanded){
                buttonIds.push('btn-'+pod.id+'-'+pol.id);
                pod.expanded = false;
              }
            }
          }
        }
        this.$nextTick(() => {
          buttonIds.forEach((id) => document.getElementById(id).click());
        });
      },

      setManifestTypes(type, format){
        this.manifestType = type;
        this.formatType = format;
        this.generatedHTML = '';
      },

      closeInnerModel(e){
        if(e.target.classList.contains('d-block')){
          this.itemLoaded = false;
        }
      },

      publish(e, port1, port2 = null){
        console.log(e.target.value, port1, port2);
        const value = e.target.value;
        const isPublished = value == 'published';
        const name = port2 ? (port1.name + ' - ' + port2.name) : port1.name;
        if(isPublished && !confirm(this.$t('manifest.confirm_publish', [name]))){
          e.preventDefault();
          return;
        }
        let publishDate = null;
        let publishUser = null;
        if(isPublished){
          publishDate = moment().format('YYYY-MM-DD');
          publishUser = store.getters.userId;
        }
        let publishInfo = isPublished ? (this.setPublishedDateString(null) + ' - ' + store.getters.getName) : null;
        if(port2){
          let commstatus = this.voyage.voyage_port_statuses.find(cs => ((cs.port_of_loading_id == port1.id || cs.port_of_loading_id === null) && cs.port_of_discharge_id == port2.id));
          if(commstatus){
            commstatus.status = value;
            commstatus.published_by_user_id = publishUser;
            commstatus.published_date = publishDate;
            port2.status = value;
            port2.publish_info = publishInfo;
            let allPodsArePublished = true;
            if(isPublished){
              port1.pods.forEach(port => {
                if(port.status != 'published'){
                  allPodsArePublished = false;
                }
              });
              if(allPodsArePublished){
                port1.publish_info = publishInfo;
                port1.status = 'published';
              }
            }
          }
        }
        else{
          let commstatuses = this.voyage.voyage_port_statuses.filter(cs => (cs.status != 'published' && (cs.port_of_loading_id == port1.id || cs.port_of_loading_id === null)) );
          commstatuses.forEach(commstatus => {
            commstatus.status = value;
            commstatus.published_by_user_id = publishUser;
            commstatus.published_date = publishDate;
            port1.publish_info = publishInfo;
            port1.status = value;
            port1.pods.forEach(port => {
              if(port.status != 'published'){
                port.publish_info = publishInfo;
                port.status = value;
              }
            });
          });
        }
        this.voyage.ports[port1.code].BLsByNumber.forEach(bl => {
          if(port2 === null || port2.code === bl.code){
            bl.status = value;
          }
        });
        voyageManifestService.updateOpenClosed(this.voyage.id, {'lines': this.voyage.voyage_port_statuses}).then(response => {
          console.log('OK closing', response);
        }).catch(error => {
          console.log('error', error);
        });
      },

      hardCopy(obj){
        return JSON.parse(JSON.stringify(obj));
      }
    }
  };
</script>

<style scoped>
.published{
  padding-top: 2px !important;
  padding-bottom: 3px !important;
}
.col-0p75{
  flex: 0 0 auto;
  width: 5.5%
}
.sticky-header{
  margin-bottom: 0.25rem;
  position: sticky;
  top: 42px;
  background-color: white;
  z-index: 1000;
}
.fa-chevron-right{
  rotate: 0deg;
  transition: rotate 0.15s linear
}
.rotate-90deg{
  rotate: 90deg
}
section > label{
  max-width: 1373px
}
.header-part-1 .data-tr{
  padding-top: 2px;
  padding-bottom: 2px;
}
.data-tr > div > * {
  vertical-align: text-top
}
.header-part-1 > .data-tr:not(:first-child), .header-part-1 > div .data-tr{
  border-top: 1px solid #ddd;
}
</style>