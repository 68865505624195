<template>
    <div>
        <Overview 
            :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
            :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
            :sort="'created_at'"
            :itemNameField="'number'"
            :itemService=itemService
            :itemFormRef=itemFormRef
            :sortOrder="2"
            modalWidth="1400px"
            :selectFields=selectFields
            :showAddButton="false"
            :blueHeader="true"
        >   
        <template #columns>
            <Column field="vessel.name" :header="$tc('navigation.vessels', 1)"></Column>
            <Column field="number" :header="$t('navigation.voyages')" style="width: 120px"></Column>
            <Column field="carrier.relation.name" :header="$tc('navigation.carriers', 1)"></Column>
            <Column field="voyage_status_id" :header="`Booking status`" style="width: 160px">
                <template #body="slotProps"> 
                    <span class="badge" :data-status="$t('voyage_status.'+slotProps.data.voyage_status_id).toLowerCase()">
                        {{slotProps.data.voyage_status_id ? $t('voyage_status.'+slotProps.data.voyage_status_id) : ''}}
                    </span>
                </template>
            </Column>
            <Column field="created_at" :header="`Created`" style="width: 100px">
                <template #body="slotProps"> {{formatDate(slotProps.data.created_at, 'shortDate')}} </template>
            </Column>
            <Column field="job_booking_voyage_manifests_count" :header="`B/Ls`" style="width: 80px" />
        </template>

        <template #form>
            <VoyageManifestForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <section id="print-footer">
                <template v-if="isAllowedForCompany">
                    <h5 class="dm-sans fw-bold">{{$t('masterdata.export_options')}}</h5>
                    <div class="col-4 mb-2 test">
                        <FormItem id="download_type_id" v-model="downloadType" type="buttongroup" sizeItem="12" :options="downloadTypes" @onchange="setDownloadTypes" />
                    </div>
                </template>
                <h5 v-else class="dm-sans fw-bold">{{$t('masterdata.export_options')}} - Bill of Lading</h5>
                <template v-if="downloadType == 3">
                    <div class="d-flex gap-2">
                        <div class="col-6">
                            <FormItem id="ops_1" v-model="extraOptions['shipper']" type="select" label="Shipper:" :options="optsShipper" @onchange="setExtraOps" sizeLabel="3" extraOption="2" :selectDefault="false" />
                            <FormItem id="ops_8" v-model="extraOptions['consignee']" type="select" label="Consignee & Notify:" :options="extraOptions['tabs'] != 1 ? optsNotify : optsNotifyAlt" @onchange="setExtraOps" sizeLabel="3" extraOption="2" :selectDefault="false" />
                            <FormItem id="ops_3" v-model="extraOptions['tabs']" type="select" label="B/L options:" :options="optsTabs" @onchange="setExtraOps" sizeLabel="3" extraOption="2" :selectDefault="false" />
                            <FormItem id="ops_5" v-model="extraOptions['format']" type="radiogroup" label="Format:" :options="optsFormat" @onchange="setExtraOps" sizeLabel="3" />
                        </div>
                        <div class="col-6">
                            <FormItem id="ops_2" v-model="extraOptions['units']" type="radiogroup" label="Units:" :options="optsUnits" @onchange="setExtraOps" sizeLabel="2" extraOption="2" />
                            <FormItem id="ops_4" v-model="extraOptions['dimensions']" type="radiogroup" label="Dimensions:" :options="optsDimensions" @onchange="setExtraOps" sizeLabel="2" extraOption="2" />
                            <div style="height: 12px"></div>
                            <FormItem id="ops_6" v-model="extraOptions['redtext']" type="checkbox" label="Add 'Only to be released to the Notify'" :options="optsFormat" @onchange="setExtraOps" sizeLabel="8" />
                            <FormItem id="ops_7" v-model="extraOptions['canada']" type="checkbox" label="NMT Canada as Notify" :options="optsFormat" @onchange="setExtraOps" sizeLabel="8" />
                        </div>
                    </div>
                </template>
                <template v-else-if="downloadType == 2">
                    <div class="mb-2" style="width: 56%">
                        <FormItem id="ops_7" v-model="extraOptions['bltype']" type="radiogroup" :label="$t('overview.type')+':'" :options="originalDisabled ? optsCopy : optsBLType" @onchange="setExtraOps" sizeLabel="2" sizeItem="4" extraOption="2" />
                        <FormItem id="ops_8" v-model="extraOptions['background']" type="radiogroup" label="B/L background:" :options="optsBackground" @onchange="setExtraOps" sizeLabel="2" sizeItem="4" extraOption="2" />
                        <FormItem id="ops_9" v-model="extraOptions['zipped']" type="radiogroup" label="Export as:" :options="formatTypesBL" @onchange="setExtraOps" sizeLabel="2" sizeItem="4" extraOption="2" />
                    </div>
                </template>
                <template v-else>
                    <div class="col-3 d-flex mb-2">
                        <label class="col-form-label col-form-label-sm" style="width: 35%">{{$t('overview.type')+':'}}</label>
                        <select id="manifest_type_id" class="form-select form-select-sm" style="width: 65%" v-model="manifestType" @change="setManifestTypes">
                            <option disabled :value="null">{{$t('form.select')}}</option>
                            <optgroup label="Manifests">
                                <option :value="1">Normal</option>
                                <option :value="2">Measurement</option>
                                <option :value="3">Agent freight</option>
                                <option :value="4">Carrier Freight</option>
                                <option :value="10">Preload cargo</option>
                            </optgroup>
                            <optgroup label="XML Downloads">
                                <option :value="5">Namibia XML</option>
                                <option :value="6">Balloré XML</option>
                                <option :value="7">GovCBR XML</option>
                            </optgroup>
                            <optgroup label="Other types">
                                <option :value="8">AMS filing</option>
                                <option :value="9">VINs list</option>
                            </optgroup>
                        </select>
                    </div>
                    <div class="mb-2" style="width: 51.5%" v-if="manifestType == 8">
                        <FormItem id="ops_6" v-model="extraOptions['relations']" type="radiogroup" label="B/L relations:" :options="optsRelations" @onchange="setExtraOps" sizeLabel="2" />
                    </div>
                    <div class="col-3">
                        <FormItem v-if="manifestType <= 2 || manifestType == 10" id="bgq-1" type="radiogroup" v-model="formatType" :label="$t('manifest.format')+':'" :options="formatTypes" @onchange="setFormatType" />
                        <FormItem v-else-if="manifestType <= 4" id="bgq-1" type="radiogroup" v-model="formatType" :label="$t('manifest.format')+':'" :options="formatTypesAC" @onchange="setFormatType" />
                        <FormItem v-else-if="manifestType == 8" id="bgq-1" type="radiogroup" v-model="formatType" :label="$t('manifest.format')+':'" :options="formatTypesAMS" @onchange="setFormatType" />
                        <FormItem v-else-if="manifestType == 9" id="bgq-1" type="radiogroup" v-model="formatType" :label="$t('manifest.format')+':'" :options="formatTypesVINs" @onchange="setFormatType" />
                        <FormItem v-else id="bgq-1" type="radiogroup" v-model="formatType" :options="formatTypesXML" :label="$t('manifest.format')+':'" @onchange="setFormatType" />
                    </div>
                    <div class="col-3" v-if="manifestType == 9">
                        <FormItem id="ops_7" label="Show description" type="checkbox" @onchange="setExtraOps" v-model="extraOptions['show_description']" />
                        <FormItem id="ops_8" label="Show BL number" type="checkbox" @onchange="setExtraOps" v-model="extraOptions['show_blnumber']" />
                    </div>
                    <div v-else style="margin-bottom: 4.2rem"></div>
                </template>
                <div class="d-flex mt-4">
                    <button id="generatebutton" type="button" class="btn btn-success col-2" :disabled="itemLoading || chosenBLLength == 0" @click="generate">
                        {{ $t('form.download') }}
                    </button>
                    <div class="progress col-2 mt-2 ms-3" v-show="itemLoading">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" :style="{width: progress + '%', transitionDuration: progressBarSpeed}"></div>
                    </div>
                    <span v-show="itemLoading" class="ms-2 pt-1">
                        {{$t('form.please_wait')}}
                    </span>
                </div>
            </section>
        </template>
        </Overview>
    </div>
</template>

<script>
import Overview from '@/components/Overview.vue';

import voyageManifestService from '@/services/VoyageManifestService';
import Column from '@/components/column';
import store from '@/store/user';
import VoyageManifestForm from '@/components/exportprint/ExportPrintForm.vue';

export default {
    components: {
        Overview,
        Column,
        VoyageManifestForm
    },
    computed: {
        isAllowedForCompany () {
            return store.getters.getCompanyHq
        }
    },
    data() {
        return {
            item: {},
            itemService: voyageManifestService,
            itemFormRef: null,
            selectFields: [
                "id",
                "number",
                "updated_at",
                "created_at",
                "region_type_id",
                "vessel_id",
                "carrier_id",
                "voyage_status_id",
                {
                    "vessel": [
                        "id",
                        "code",
                        "name"
                    ]
                },
                {
                    "carrier": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "id",
                                "name"
                            ]
                        }
                    ]
                }
            ],
            downloadType: 2,
            formatType: 1,
            manifestType: 1,
            extraOptions: {"shipper": 1, "units": 1, "tabs": 1, "dimensions": 1, "format": 1, "relations": 1, "show_description": 0, "bltype": 1, "background": 1, "zipped": 1, "redtext": 0, "canada": 0, "consignee": 1, "show_blnumber": 0},
            downloadTypes: [{id: 2, name: 'Bill of Lading'}, {id: 1, name: 'Manifest'}, {id: 3, name: 'Carrier B/L Request'}],
            manifestTypes: [{id: 101, name: 'Manifests', header: true}, {id: 1, name: 'Normal'}, {id: 2, name: 'Measurement'}, {id: 3, name: 'Agent freight'}, {id: 4, name: 'Carrier Freight'}, {id: 10, name: 'Preload cargo'},
                            {id: 102, name: 'XML Downloads', header: true}, {id: 5, name: 'Namibia XML'}, {id: 6, name: 'Balloré XML'}, {id: 7, name: 'GovCBR XML'}, 
                            {id: 103, name: 'Other types', header: true}, {id: 9, name: 'AMS filing'}, {id: 8, name: 'VINs list'}],
            formatTypes: [/*{id: 0, name: 'Preview'},*/{id: 1, name: 'PDF'}, {id: 2, name: 'CSV'}, {id: 3, name: 'Excel'}],
            formatTypesVINs: [{id: 1, name: 'PDF', disabled: true}, {id: 2, name: 'CSV'}, {id: 3, name: 'Excel'}],
            formatTypesAMS: [{id: 1, name: 'PDF', disabled: true}, {id: 2, name: 'CSV', disabled: true}, {id: 3, name: 'Excel'}],
            formatTypesAC: [{id: 1, name: 'PDF'}, {id: 2, name: 'CSV'}, {id: 3, name: 'Excel', disabled: true}],
            formatTypesBL: [{id: 1, name: 'Single PDF'}, {id: 2, name: 'Zip File'}],
            formatTypesXML: [{id: 1, name: 'XML'}],
            optsShipper: [{id: 1, name: 'NMTC details'}, {id: 2, name: 'Original shipper'}, {id: 3, name: 'NMT INTERNATIONAL C/O + full shipper'}],
            optsNotify: [{id: 1, name: 'Port representative'}, {id: 2, name: 'Original consignee & notify'}],
            optsNotifyAlt: [{id: 1, name: 'Port representative'}],
            optsUnits: [{id: 1, name: 'Totals only (attach manifest)'}, {id: 2, name: 'Specify units'}],
            optsTabs: [{id: 1, name: 'Combine all Bs/L per PoL/PoD per tab'}, {id: 2, name: '1 B/L per Excel tab'}, {id: 3, name: '1 B/L per Excel file'}],
            optsDimensions: [{id: 1, name: 'Show all dimensions'}, {id: 2, name: 'Only show weight & volume'}],
            optsFormat: [{id: 1, name: 'Excel'}],
            optsRelations: [{id: 1, name: 'NMTC + representative'}, {id: 2, name: 'Actual shipper/consignee/notify'}],
            optsBLType: [{id: 1, name: 'Copy'}, {id: 2, name: 'Original'}],
            optsCopy: [{id: 1, name: 'Copy'}, {id: 2, name: 'Original', disabled: true}],
            optsBackground: [{id: 1, name: 'Yes'}, {id: 2, name: 'No'}],
            originalDisabled: false,
            canEdit: false,
            itemLoading: false,
            progress: 25,
            progressBarSpeed: '0.6s',
            chosenBLLength: 0
        }
    },
    methods: {
        setDownloadTypes(){
            this.$refs.ItemForm.downloadType = this.downloadType;
        },
        setExtraOps(){
            if(this.extraOptions.tabs == 1)
                this.extraOptions.consignee = 1;
            this.$refs.ItemForm.extraOptions = this.extraOptions;
        },
        setManifestTypes(){
            if(this.manifestType > 2 && this.manifestType != 10){
                if(this.manifestType == 8){
                    this.formatType = 3;
                }
                else if(this.manifestType == 9){
                    if(this.formatType == 1) this.formatType = 2;
                }
                else if(this.manifestType > 4){
                    this.formatType = 1;
                }
                else if(this.formatType > 2){
                    this.formatType = 2;
                }
            }
            this.$refs.ItemForm.setManifestTypes(this.manifestType, this.formatType);
        },
        setFormatType(){
            this.$refs.ItemForm.formatType = this.formatType;
        },
        generate(){
            this.progress = 5;
            this.setDownloadTypes();
            this.setExtraOps();
            this.setManifestTypes();
            this.setFormatType();
            this.$refs.ItemForm.generate();
            let pbarStep = Math.round(this.chosenBLLength * 4) / 100;
            if((this.downloadType == 2 && this.extraOptions['zipped'] == 2) || (this.downloadType == 1 && this.formatType == 1 && (this.manifestType == 1 || this.manifestType == 2))){
                pbarStep = 0.2;
            }
            this.progressBarSpeed = (pbarStep + 0.2) + 's';
            console.log('L', this.progressBarSpeed);
        },

        disableOriginal(){
            this.originalDisabled = true;
            this.extraOptions['bltype'] = 1;
        },
        enableOriginal(){
            //if(this.canEdit){
                this.originalDisabled = false;
            //}
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        this.canEdit = store.getters.getCompanyHq ? true : false;
    }
}
</script>
<style>
#export-and-print-popup .modal-footer{
    background-color:#f1f0ec !important;
}
#export-and-print-popup #savebutton{
    display: none;
}
#print-footer{
    width: 100%;
}
#generatebutton{
    margin-left: 8.25rem;
    width: 15.25rem;
}
</style>